import React, { ReactElement, ReactNode, memo } from 'react'

import { Variant } from '../../../themes'
import Tooltip from '../Tooltip/Tooltip'
import { StyledChip } from './Chip.styles'

export interface ChipProps {
  'data-e2e'?: string
  bold?: boolean
  children?: ReactNode
  className?: string
  off?: boolean
  tooltip?: string
  trigger?: 'hover' | 'click'
  variant?: Variant
  withBorder?: boolean
}

const Chip = ({
  'data-e2e': datae2e,
  bold = false,
  children,
  className,
  off = false,
  tooltip,
  trigger = 'hover',
  variant = 'secondary',
  withBorder = false,
}: ChipProps) => {
  if (off) return children as ReactElement

  if (tooltip)
    return (
      <Tooltip
        data-e2e={`${datae2e}--tooltip`}
        tooltip={tooltip}
        variant="black"
        placement="top"
        trigger={trigger}
      >
        <StyledChip
          bold={bold}
          className={className}
          data-e2e={datae2e}
          variant={variant}
          withBorder={withBorder}
        >
          {children}
        </StyledChip>
      </Tooltip>
    )

  return (
    <StyledChip
      bold={bold}
      className={className}
      data-e2e={datae2e}
      variant={variant}
      withBorder={withBorder}
    >
      {children}
    </StyledChip>
  )
}

export default memo(Chip)
