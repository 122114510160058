import React from 'react'

import { MyOptionType, Select, Text } from '@procsea/design-system'

import { useBuyerOptionFlags, useSegment } from 'src/hooks'
import { BuyerFilter, ProductsOrdering } from 'src/types'

export interface BuyerLiveSortingSelectProps {
  onOrdering: (ordering: ProductsOrdering[]) => void
  filters?: BuyerFilter
}

export const sortingByDiscount: ProductsOrdering[] = [
  'tags_eq_promotion',
  'long_description',
  'calibre',
  'price',
]
export const sortingByOpportunity: ProductsOrdering[] = [
  '-is_opportunity',
  'long_description',
  'calibre',
  'price',
]

const BuyerLiveSortingSelect = ({ onOrdering, filters }: BuyerLiveSortingSelectProps) => {
  const { optionFlagShowSupplierOpportunities } = useBuyerOptionFlags()
  const { track } = useSegment()
  const filteredSellerCompanyIds = filters?.sellerCompanyId || []
  const isFilteringBySellerCompany = filteredSellerCompanyIds.length !== 0

  const shouldSortByOpportunity = optionFlagShowSupplierOpportunities && isFilteringBySellerCompany

  const sortingByRelevance: ProductsOrdering[] = shouldSortByOpportunity
    ? sortingByOpportunity
    : sortingByDiscount

  const options: MyOptionType<ProductsOrdering[]>[] = [
    {
      label: gettext('Relevance'),
      value: sortingByRelevance,
    },
    {
      label: gettext('Name'),
      value: ['name'],
    },
    {
      label: gettext('Biggest calibres'),
      value: ['-calibre'],
    },
    {
      label: gettext('Smallest calibres'),
      value: ['calibre'],
    },
    {
      label: gettext('Price'),
      value: ['price'],
    },
  ]

  const handleOrdering = ({ value }: { value: ProductsOrdering[] | null }) => {
    if (value !== null) {
      onOrdering(value)
      track('Buyer - Basic Spi List - Sorted', {
        columnName: value,
      })
    }
  }

  return (
    <div>
      <Text variant="secondary">{gettext('Sorted by:')}</Text>
      <Select<ProductsOrdering[], false>
        data-e2e="sorting-select"
        inline
        onChange={handleOrdering}
        options={options}
        rightPosition
        value={filters?.ordering}
      />
    </div>
  )
}

export default BuyerLiveSortingSelect
