import makeActionCreator from './makeActionCreator'

/* Action types */
export const SHOW_TIMEOUT_NOTIFICATION = 'SHOW_TIMEOUT_NOTIFICATION'
export const HIDE_TIMEOUT_NOTIFICATION = 'HIDE_TIMEOUT_NOTIFICATION'

export const SHOW_ORDER_DETAIL_PORTAL = 'SHOW_ORDER_DETAIL_PORTAL'
export const HIDE_ORDER_DETAIL_PORTAL = 'HIDE_ORDER_DETAIL_PORTAL'

/* Action creators */
export const showTimeoutNotification = makeActionCreator(SHOW_TIMEOUT_NOTIFICATION, 'timeout')
export const hideTimeoutNotification = makeActionCreator(HIDE_TIMEOUT_NOTIFICATION)

export interface ShowOrderDetailPortalAction {
  type: typeof SHOW_ORDER_DETAIL_PORTAL
  orderId: Id
}
export const showOrderDetailPortal = makeActionCreator(SHOW_ORDER_DETAIL_PORTAL, 'orderId')
export const hideOrderDetailPortal = makeActionCreator(HIDE_ORDER_DETAIL_PORTAL, 'orderId')
