import styled from 'styled-components'

import { Size } from '../../../themes/types'

type Props = {
  spacing: Size
}

export const StyledSubHeader = styled.div<Props>`
  background-color: ${props => props.theme.colors.primary.lighter};
  padding: ${props =>
    `${props.theme.spacings.small} 0px ${props.theme.spacings.small} ${
      props.theme.spacings[props.spacing]
    }`};
  margin: ${props => `0 -${props.theme.spacings[props.spacing]}`};
`
