import styled from 'styled-components'

import { PageContentProps } from './PageContent'

export const StyledPageContent = styled.div<
  PageContentProps & { isAlongsideBurgerMenu: boolean; isLegacy: boolean; isMobile: boolean }
>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  padding-bottom: ${props => props.theme.spacings.medium};

  ${props =>
    !props.isLegacy &&
    props.isAlongsideBurgerMenu &&
    `padding: ${props.theme.menu.dimensions.mobile.topBarHeight} 0 ${props.theme.spacings.medium} 0 !important;
    min-height: 100vh;
      &::-webkit-scrollbar {
    display: none;
  }`}

  ${props =>
    props.isLegacy &&
    props.isMobile &&
    `padding: ${props.theme.dimensions.mobileHeaderHeight} 0 calc(${props.theme.dimensions.mobileTabBarHeight} + ${props.theme.spacings.medium}) 0 !important;
    min-height: 100vh;
      &::-webkit-scrollbar {
    display: none;
  }`}
`
