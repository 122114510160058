import { Client, Conversation } from '@twilio/conversations'
import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  CHAT_ENDPOINT,
  addConversation,
  getConversationsFailed,
  getConversationsRequest,
  getConversationsSuccess,
  removeConversation,
  updateConversation,
} from 'src/action-creators/conversations'
import { checkIfSessionExpired, getRequestInit } from 'src/utils'

export const ConversationContext = React.createContext<
  [Client | null, React.Dispatch<React.SetStateAction<Client | null>>]
>([null, () => {}])

export const getToken = async () => {
  const response = await fetch(`${CHAT_ENDPOINT}/token/`, getRequestInit({ method: 'GET' }))
  checkIfSessionExpired(response)

  const { token } = await response.json()
  return token
}

export const createConversationClient = async () => {
  const token = await getToken()
  return token ? new Client(token) : null
}

function useChat() {
  const dispatch = useDispatch()
  const [clientConversation] = useContext(ConversationContext)

  const getChannels = async () => {
    if (clientConversation) {
      try {
        dispatch(getConversationsRequest())

        const conversationClient = await createConversationClient()
        const paginatedConversations = await conversationClient?.getSubscribedConversations()

        dispatch(getConversationsSuccess(paginatedConversations?.items))

        return paginatedConversations
      } catch (fetchError) {
        dispatch(getConversationsFailed(fetchError))
      }
    }
  }

  const handleConversationAdded = (conversation: Conversation) => {
    dispatch(addConversation(conversation))
  }

  const handleConversationUpdated = ({ conversation }: { conversation: Conversation }) => {
    dispatch(updateConversation(conversation))
  }

  const handleConversationRemoved = (conversation: Conversation) => {
    dispatch(removeConversation(conversation))
  }

  const handleTokenRefresh = () => {
    if (clientConversation) {
      getToken().then(token => {
        clientConversation.updateToken(token)
      })
    }
  }

  useEffect(() => {
    if (clientConversation) {
      getChannels().then(() => {
        clientConversation.on('conversationAdded', handleConversationAdded)
        clientConversation.on('conversationRemoved', handleConversationRemoved)
        clientConversation.on('conversationUpdated', handleConversationUpdated)
        clientConversation.on('tokenAboutToExpire', handleTokenRefresh)
      })
    }

    return () => {
      if (clientConversation) {
        clientConversation.removeAllListeners()
      }
    }
  }, [clientConversation])

  return clientConversation
}

export default useChat
