import { ACTION_PAYLOAD } from './constants'
import makeActionCreator from './makeActionCreator'

/* Action types */
export const UPDATE_BUYER_MARKETPLACE_FILTERS = 'UPDATE_BUYER_MARKETPLACE_FILTERS'
export const RESET_BUYER_MARKETPLACE_FILTERS = 'RESET_BUYER_MARKETPLACE_FILTERS'

/* Action creators */
export const updateBuyerFilters = makeActionCreator(
  UPDATE_BUYER_MARKETPLACE_FILTERS,
  ACTION_PAYLOAD
)
export const resetBuyerFilters = makeActionCreator(RESET_BUYER_MARKETPLACE_FILTERS)
