import { get } from 'lodash'

import { ACTION_PAYLOAD } from 'src/action-creators/constants'
import {
  RESET_BUYER_MARKETPLACE_FILTERS,
  UPDATE_BUYER_MARKETPLACE_FILTERS,
} from 'src/action-creators/filters'
import { sortingByDiscount } from 'src/components/presentationals/filters/BuyerLiveSortingSelect'
import createReducer from 'src/reducers/createReducer'
import { BuyerFilterState } from 'src/types'

export enum BuyerFilterStateFields {
  CATEGORY_ID = 'categoryId',
  DELIVERABLE_DATES = 'deliverableDates',
  FISHING_PORT_COUNTRY = 'fishingPortCountry',
  IS_FAVORITE = 'isFavorite',
  IS_FREQUENT = 'isFrequent',
  LABEL_ID = 'labelId',
  ORDERING = 'ordering',
  PREPARATION = 'preparation',
  PRODUCT_LINE_TAGS = 'productLineTags',
  Q = 'q',
  QUALITY = 'quality',
  SELF_SERVICE = 'selfService',
  SELLER_COMPANY_ID = 'sellerCompanyId',
  TYPE = 'type',
  IS_MAPPED = 'isMapped',
}

export const INITIAL_BUYER_MARKETPLACE_FILTERS: BuyerFilterState = {
  categoryId: '',
  deliverableDates: [],
  fishingPortCountry: [],
  isFavorite: null,
  isFrequent: null,
  labelId: [],
  ordering: sortingByDiscount,
  preparation: undefined,
  productLineTags: undefined,
  q: '',
  quality: [],
  selfService: null,
  sellerCompanyId: [],
  type: undefined,
  isMapped: null,
}

const updateBuyerFilters = (
  state: BuyerFilterState,
  action: { type: string; [ACTION_PAYLOAD]?: BuyerFilterState } // @TODO: Type BuyerFilter Actions and use union type here.
) => ({
  ...state,
  ...get(action, ACTION_PAYLOAD),
})

const resetBuyerFilters = () => INITIAL_BUYER_MARKETPLACE_FILTERS

export default createReducer(INITIAL_BUYER_MARKETPLACE_FILTERS, {
  [UPDATE_BUYER_MARKETPLACE_FILTERS]: updateBuyerFilters,
  [RESET_BUYER_MARKETPLACE_FILTERS]: resetBuyerFilters,
})
