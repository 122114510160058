import { createBrowserHistory } from 'history'
import { AnyAction, Middleware, applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'

import { INITIAL_BUYER_MARKETPLACE_FILTERS } from 'src/reducers'
import { BuyerFilterState } from 'src/types'

import updateQueryParams from '../middlewares/updateQueryParams'
import rootReducer from '../reducers/rootReducer'
import { applyQueryParamsFilters } from '../utils/query/queryParams'
import INITIAL_ROOT_STATE, { RootState } from './initialStore'

export const history = createBrowserHistory()
export const BUYER_MARKET_PAGE_REGEXP = /\/buyer\/marketplace\/[\d]+\/market\/[\D]+\/live\/$/

const middlewareList: Middleware[] = [thunk, updateQueryParams(history)]

const middlewares = applyMiddleware(...middlewareList)

export const getBuyerFilterState = (): BuyerFilterState => INITIAL_BUYER_MARKETPLACE_FILTERS

const initialBuyerFiltersState = applyQueryParamsFilters(
  window.location,
  getBuyerFilterState(),
  BUYER_MARKET_PAGE_REGEXP
) as BuyerFilterState

const configureStore = (
  preloadedState = {
    ...INITIAL_ROOT_STATE,
    filters: initialBuyerFiltersState,
  }
) => {
  const store = createStore<RootState, AnyAction, unknown, unknown>(
    rootReducer,
    { ...preloadedState },
    middlewares
  )
  return store
}

export default configureStore
