import * as reducers from 'src/reducers'

const INITIAL_ROOT_STATE = {
  conversationContacts: reducers.INITIAL_CONVERSATION_CONTACTS_STATE,
  conversations: reducers.INITIAL_CONVERSATIONS_STATE,
  filters: reducers.INITIAL_BUYER_MARKETPLACE_FILTERS,
  notifications: reducers.INITIAL_NOTIFICATIONS_STATE,
  vigilance: reducers.INITIAL_VIGILANCE_STATE,
}

export default INITIAL_ROOT_STATE

export type RootState = typeof INITIAL_ROOT_STATE
