import { Dispatch } from 'redux'

import { API_URL } from 'src/constants/constants'
import Api from 'src/services/Api'
import { BuyerCompany, SellerCompany } from 'src/types'
import { GetSellerCompaniesRequest } from 'src/types'
import { transformObjectToQuery } from 'src/utils/query/query'

import { ACTION_ERROR, ACTION_PAYLOAD } from './constants'
import makeActionCreator from './makeActionCreator'
import { APIErrorResponse } from './types'

export const BUYER_COMPANIES_ENDPOINT = `${API_URL}/buyer_companies/`

/* Action types */
export const GET_CONVERSATION_CONTACTS_REQUEST = 'GET_CONVERSATION_CONTACTS_REQUEST'
export const GET_CONVERSATION_CONTACTS_SUCCESS = 'GET_CONVERSATION_CONTACTS_SUCCESS'
export const GET_CONVERSATION_CONTACTS_FAILED = 'GET_CONVERSATION_CONTACTS_FAILED'

export interface GetConversationContactsRequestAction {
  type: typeof GET_CONVERSATION_CONTACTS_REQUEST
}

export interface GetConversationContactsSuccessAction {
  type: typeof GET_CONVERSATION_CONTACTS_SUCCESS
  [ACTION_PAYLOAD]: Array<BuyerCompany | SellerCompany>
}

export interface GetConversationContactsFailedAction {
  type: typeof GET_CONVERSATION_CONTACTS_FAILED
  [ACTION_ERROR]: APIErrorResponse
}

/* Action creators */
export const getConversationContactsRequest =
  makeActionCreator<GetConversationContactsRequestAction>(GET_CONVERSATION_CONTACTS_REQUEST)
export const getConversationContactsSuccess =
  makeActionCreator<GetConversationContactsSuccessAction>(
    GET_CONVERSATION_CONTACTS_SUCCESS,
    ACTION_PAYLOAD
  )
export const getConversationContactsFailed = makeActionCreator<GetConversationContactsFailedAction>(
  GET_CONVERSATION_CONTACTS_FAILED,
  ACTION_ERROR
)

/* Thunk action creators */
export const getBuyerConversationContacts =
  (dispatch: Dispatch) => (marketplaceArgs: { marketplaceId?: Id }, buyerCompanyId: Id) => {
    dispatch(getConversationContactsRequest())
    const filters: GetSellerCompaniesRequest = {
      buyerCompanyId,
      canUseChat: true,
      connected: true,
    }
    return Api.get(
      `${API_URL}/marketplaces/${
        marketplaceArgs.marketplaceId
      }/seller_companies/${transformObjectToQuery(filters)}`
    ).then(
      Api.responseManager(dispatch, getConversationContactsSuccess, getConversationContactsFailed)
    )
  }

export const getSellerConversationContacts =
  (dispatch: Dispatch) =>
  ({ sellerCompanyId }: { sellerCompanyId: Id }) => {
    dispatch(getConversationContactsRequest())

    const queryParams = transformObjectToQuery({
      canUseChat: true,
      hasContract: true,
      hasConnectedRoute: true,
      sellerCompanyId,
    })

    return Api.get(`${BUYER_COMPANIES_ENDPOINT}${queryParams}`).then(
      Api.responseManager(dispatch, getConversationContactsSuccess, getConversationContactsFailed)
    )
  }
