import { ACTION_PAYLOAD } from 'src/action-creators'
import {
  ADD_CONVERSATION,
  AddConversationAction,
  CREATE_CONVERSATION_FAILED,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_SUCCESS,
  GET_CONVERSATIONS_FAILED,
  GET_CONVERSATIONS_REQUEST,
  GET_CONVERSATIONS_SUCCESS,
  GetConversationsSuccessAction,
  REMOVE_CONVERSATION,
  RemoveConversationAction,
  UPDATE_CONVERSATION,
  UpdateConversationAction,
} from 'src/action-creators/conversations'

import createReducer from './createReducer'

export interface ConversationsState {
  conversations: any[]
  isCreatingConversation: boolean
  isLoading: boolean
}

export const INITIAL_CONVERSATIONS_STATE: ConversationsState = {
  conversations: [],
  isCreatingConversation: false,
  isLoading: false,
}

const addConversation = (state: ConversationsState, action: AddConversationAction) => ({
  ...state,
  conversations: [action[ACTION_PAYLOAD], ...state.conversations],
})

const createConversationRequest = (state: ConversationsState) => ({
  ...state,
  isCreatingConversation: true,
})

const createConversationSuccess = (state: ConversationsState) => ({
  ...state,
  isCreatingConversation: false,
})

const createConversationFailed = (state: ConversationsState) => ({
  ...state,
  isCreatingConversation: false,
})

const getConversationsRequest = (state: ConversationsState) => ({
  ...state,
  isLoading: true,
})

const getConversationsSuccess = (
  state: ConversationsState,
  action: GetConversationsSuccessAction
) => ({
  ...state,
  conversations: action[ACTION_PAYLOAD],
  isLoading: false,
})

const getConversationsFailed = (state: ConversationsState) => ({
  ...state,
  isLoading: false,
})

const updateConversation = (state: ConversationsState, action: UpdateConversationAction) => ({
  ...state,
  conversations: [
    action[ACTION_PAYLOAD],
    ...state.conversations.filter(conversation => conversation.sid !== action[ACTION_PAYLOAD].sid),
  ],
})

const removeConversation = (state: ConversationsState, action: RemoveConversationAction) => ({
  ...state,
  conversations: state.conversations.filter(
    conversation => conversation.sid !== action[ACTION_PAYLOAD].sid
  ),
})

const conversations = createReducer(INITIAL_CONVERSATIONS_STATE, {
  [ADD_CONVERSATION]: addConversation,
  [CREATE_CONVERSATION_REQUEST]: createConversationRequest,
  [CREATE_CONVERSATION_SUCCESS]: createConversationSuccess,
  [CREATE_CONVERSATION_FAILED]: createConversationFailed,
  [GET_CONVERSATIONS_REQUEST]: getConversationsRequest,
  [GET_CONVERSATIONS_SUCCESS]: getConversationsSuccess,
  [GET_CONVERSATIONS_FAILED]: getConversationsFailed,
  [UPDATE_CONVERSATION]: updateConversation,
  [REMOVE_CONVERSATION]: removeConversation,
})

export default conversations
