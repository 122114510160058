import styled from 'styled-components'

import { Variant } from '../../../themes'

export const StyledChip = styled.div<{ bold: boolean; variant: Variant; withBorder: boolean }>`
  display: inline-flex;
  align-items: center;
  line-height: 1;
  padding: ${props => props.theme.spacingsInset.small};
  border-radius: ${props => props.theme.dimensions.borderRadius};
  background-color: ${props => props.theme.colors[props.variant].lightest};
  color: ${props => props.theme.colors[props.variant].base};
  height: 2rem;
  max-height: 2rem;
  white-space: nowrap;
  box-sizing: border-box;
  ${props => props.bold && `font-weight: ${props.theme.fontWeights.bold}`};
  ${props => props.withBorder && `border: 1px solid`};
`
