import { head } from 'lodash'

import { useBuyerCompaniesQuery } from 'src/queries/buyer'

export const useBuyerOptionFlags = () => {
  const { data: buyerCompanies } = useBuyerCompaniesQuery()
  const activeBuyerCompany = head(buyerCompanies)
  return {
    optionFlagShowSupplierOpportunities: activeBuyerCompany?.optionFlagShowSupplierOpportunities,
  }
}
