import { Conversation } from '@twilio/conversations'
import { Dispatch } from 'redux'

import { API_URL } from 'src/constants/constants'
import Api from 'src/services/Api'

import { ACTION_ERROR, ACTION_PAYLOAD } from './constants'
import makeActionCreator from './makeActionCreator'
import { APIErrorResponse, ErrorAction } from './types'

export const CHAT_ENDPOINT = `${API_URL}/chat`

/* Action types */
export const ADD_CONVERSATION = 'ADD_CONVERSATION'
export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST'
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS'
export const CREATE_CONVERSATION_FAILED = 'CREATE_CONVERSATION_FAILED'
export const GET_CONVERSATIONS_REQUEST = 'GET_CONVERSATIONS_REQUEST'
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS'
export const GET_CONVERSATIONS_FAILED = 'GET_CONVERSATIONS_FAILED'
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION'
export const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION'

export interface AddConversationAction {
  type: typeof ADD_CONVERSATION
  [ACTION_PAYLOAD]: Conversation
}

export interface CreateConversationRequestAction {
  type: typeof CREATE_CONVERSATION_REQUEST
}

export interface CreateConversationSuccessAction {
  type: typeof CREATE_CONVERSATION_SUCCESS
  [ACTION_PAYLOAD]: { conversation_id: string }
}

export type CreateConversationFailedAction = ErrorAction<typeof CREATE_CONVERSATION_FAILED>

export interface GetConversationsRequestAction {
  type: typeof GET_CONVERSATIONS_REQUEST
}

export interface GetConversationsSuccessAction {
  type: typeof GET_CONVERSATIONS_SUCCESS
  [ACTION_PAYLOAD]: Conversation[]
}

export interface GetConversationsFailedAction {
  type: typeof GET_CONVERSATIONS_FAILED
  [ACTION_ERROR]: APIErrorResponse
}

export interface UpdateConversationAction {
  type: typeof UPDATE_CONVERSATION
  [ACTION_PAYLOAD]: Conversation
}

export interface RemoveConversationAction {
  type: typeof REMOVE_CONVERSATION
  [ACTION_PAYLOAD]: Conversation
}

/* Action creators */
export const addConversation = makeActionCreator<AddConversationAction>(
  ADD_CONVERSATION,
  ACTION_PAYLOAD
)
export const createConversationRequest = makeActionCreator<CreateConversationRequestAction>(
  CREATE_CONVERSATION_REQUEST
)
export const createConversationSuccess = makeActionCreator<CreateConversationSuccessAction>(
  CREATE_CONVERSATION_SUCCESS,
  ACTION_PAYLOAD
)
export const createConversationFailed = makeActionCreator<CreateConversationFailedAction>(
  CREATE_CONVERSATION_FAILED,
  ACTION_ERROR
)
export const getConversationsRequest =
  makeActionCreator<GetConversationsRequestAction>(GET_CONVERSATIONS_REQUEST)
export const getConversationsSuccess = makeActionCreator<GetConversationsSuccessAction>(
  GET_CONVERSATIONS_SUCCESS,
  ACTION_PAYLOAD
)
export const getConversationsFailed = makeActionCreator<GetConversationsFailedAction>(
  GET_CONVERSATIONS_FAILED,
  ACTION_ERROR
)
export const updateConversation = makeActionCreator<UpdateConversationAction>(
  UPDATE_CONVERSATION,
  ACTION_PAYLOAD
)
export const removeConversation = makeActionCreator<RemoveConversationAction>(
  REMOVE_CONVERSATION,
  ACTION_PAYLOAD
)

/* Thunk action creators */
export const createConversation =
  (dispatch: Dispatch) =>
  (body: { buyerCompanyId: Id; marketplaceId: Id; sellerCompanyId: Id }) => {
    dispatch(createConversationRequest(body))
    return Api.post(`${CHAT_ENDPOINT}/channels/`, body).then(
      Api.responseManager(dispatch, createConversationSuccess, createConversationFailed)
    )
  }
