import { combineReducers } from 'redux'

import { RootState } from 'src/store/initialStore'

import conversationContacts from './conversationContacts'
import conversations from './conversations'
import filters from './filters'
import notifications from './notifications'
import reduceVigilance from './vigilance'

const rootReducer = combineReducers<RootState>({
  conversations,
  conversationContacts,
  filters,
  notifications,
  vigilance: reduceVigilance,
})

export default rootReducer
