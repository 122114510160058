import { ACTION_PAYLOAD } from 'src/action-creators/constants'
import {
  GET_CONVERSATION_CONTACTS_FAILED,
  GET_CONVERSATION_CONTACTS_REQUEST,
  GET_CONVERSATION_CONTACTS_SUCCESS,
  GetConversationContactsSuccessAction,
} from 'src/action-creators/conversationContacts'
import { BuyerCompany, SellerCompany } from 'src/types'
import { ConversationContact } from 'src/types/conversationContacts'

import createReducer from './createReducer'

export interface ConversationContactsState {
  contacts: ConversationContact[]
  isLoading: boolean
}

export const INITIAL_CONVERSATION_CONTACTS_STATE: ConversationContactsState = {
  contacts: [],
  isLoading: false,
}

export const transformCompanyToContact = (
  company: BuyerCompany | SellerCompany
): ConversationContact => ({
  id: company.id,
  marketplaceIds: (company as BuyerCompany).marketplaceIds || [],
  name: company.name,
})

const getConversationContactsRequest = (state: ConversationContactsState) => ({
  ...state,
  isLoading: true,
})

const getConversationContactsSuccess = (
  state: ConversationContactsState,
  action: GetConversationContactsSuccessAction
) => ({
  ...state,
  contacts: action[ACTION_PAYLOAD].map(transformCompanyToContact),
  isLoading: false,
})

const getConversationContactsFailed = (state: ConversationContactsState) => ({
  ...state,
  isLoading: false,
})

const conversationContacts = createReducer(INITIAL_CONVERSATION_CONTACTS_STATE, {
  [GET_CONVERSATION_CONTACTS_REQUEST]: getConversationContactsRequest,
  [GET_CONVERSATION_CONTACTS_SUCCESS]: getConversationContactsSuccess,
  [GET_CONVERSATION_CONTACTS_FAILED]: getConversationContactsFailed,
})

export default conversationContacts
