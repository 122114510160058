import { RouteComponentProps } from 'react-router-dom'
import { AnyAction } from 'redux'

import { ACTION_PAYLOAD } from 'src/action-creators/constants'
import {
  RESET_BUYER_MARKETPLACE_FILTERS,
  UPDATE_BUYER_MARKETPLACE_FILTERS,
} from 'src/action-creators/filters'
import { appendQueryParams } from 'src/utils/query/queryParams'

/*
 * This middleware will update the query params when the user updates the filters
 */
export default (history: RouteComponentProps['history']) =>
  () =>
  (next: (action: AnyAction) => void) =>
  (action: AnyAction) => {
    switch (action.type) {
      case UPDATE_BUYER_MARKETPLACE_FILTERS:
        appendQueryParams(action[ACTION_PAYLOAD], history)
        break
      case RESET_BUYER_MARKETPLACE_FILTERS:
        appendQueryParams({}, history)
        break
      default:
        break
    }

    return next(action)
  }
