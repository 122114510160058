import styled from 'styled-components'

import { Breakpoint } from '../../../hooks'
import Container from '../Container/Container'
import { Alignment, FooterProps } from './Footer.types'

export const StyledFooter = styled.div<FooterProps & { activeBreakpoint: Breakpoint }>`
  @keyframes appear {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  position: fixed;
  bottom: ${props =>
    props.activeBreakpoint === Breakpoint.IS_MOBILE
      ? props.theme.dimensions.mobileTabBarHeight
      : 0};
  left: 0;
  width: 100%;
  animation-name: appear;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  background-color: ${props => props.theme.colors.white.base};
  box-shadow: ${props => props.theme.shadows.middle};
  padding-bottom: ${props => props.theme.spacings.medium};
  padding-top: ${props => props.theme.spacings.medium};
  ${props =>
    (props.activeBreakpoint === Breakpoint.IS_SMALL_DESKTOP ||
      props.activeBreakpoint === Breakpoint.IS_MEDIUM_DESKTOP ||
      props.activeBreakpoint === Breakpoint.IS_LARGE_DESKTOP) &&
    `padding-left: ${props.theme.dimensions.computerNavigationWidth};`}
  ${props =>
    props.activeBreakpoint === Breakpoint.IS_TABLET &&
    `padding-left: ${props.theme.dimensions.tabletNavigationWidth};`}
    z-index: 1;
`

export const FooterOffset = styled.div<{ footerOffset: number }>`
  width: 100%;
  height: ${props => props.footerOffset}px;
`

export const FooterContainer = styled(Container)<{ alignment?: Alignment }>`
  ${props =>
    props.alignment &&
    `
  display: flex;
  justify-content: ${props.alignment};
`}
`
