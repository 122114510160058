import {
  HIDE_ORDER_DETAIL_PORTAL,
  HIDE_TIMEOUT_NOTIFICATION,
  SHOW_ORDER_DETAIL_PORTAL,
  SHOW_TIMEOUT_NOTIFICATION,
  ShowOrderDetailPortalAction,
} from 'src/action-creators/vigilance'

import createReducer from './createReducer'

export interface VigilanceState {
  showTimeoutNotification: boolean
  showBuyingProductPortal: Id | null
  showOrderDetailPortal: Id | null
}

export const INITIAL_VIGILANCE_STATE: VigilanceState = {
  showTimeoutNotification: false,
  showBuyingProductPortal: null,
  showOrderDetailPortal: null,
}

const showTimeoutNotification = (state: VigilanceState) => ({
  ...state,
  showTimeoutNotification: true,
})

const hideTimeoutNotification = (state: VigilanceState) => ({
  ...state,
  showTimeoutNotification: false,
})

const showOrderDetailPortal = (state: VigilanceState, action: ShowOrderDetailPortalAction) => ({
  ...state,
  showOrderDetailPortal: action.orderId,
})

const hideOrderDetailPortal = (state: VigilanceState) => ({
  ...state,
  showOrderDetailPortal: null,
})

export const reduceVigilance = createReducer(INITIAL_VIGILANCE_STATE, {
  [HIDE_TIMEOUT_NOTIFICATION]: hideTimeoutNotification,
  [HIDE_ORDER_DETAIL_PORTAL]: hideOrderDetailPortal,
  [SHOW_ORDER_DETAIL_PORTAL]: showOrderDetailPortal,
  [SHOW_TIMEOUT_NOTIFICATION]: showTimeoutNotification,
})

export default reduceVigilance
