import { animated, useTransition } from '@react-spring/web'
import React, { ReactNode, useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { Width } from '../../../themes'
import { replaceWidthValueAndKeepUnit } from '../../../utils'
import { StyledPanel, StyledPanelBackground, StyledPanelContent } from './SlidingPanel.styles'
import SlidingPanelFooter from './SlidingPanelFooter/SlidingPanelFooter'
import SlidingPanelHeader from './SlidingPanelHeader/SlidingPanelHeader'

export const DEFAULT_SLIDING_PANEL_WIDTH: Width = '320px'

const AnimatedPanel = animated(StyledPanel)
const AnimatedPanelBackground = animated(StyledPanelBackground)

export interface SlidingPanelProps {
  'data-e2e'?: string
  children?: ReactNode
  className?: string
  footer?: React.ReactNode
  header?: React.ReactNode
  onAfterClose?: () => void
  onClose: () => void
  open: boolean
  width?: Width
}

const SlidingPanel = ({
  'data-e2e': dataE2e = 'sliding-panel',
  children,
  className,
  footer,
  header,
  onClose,
  onAfterClose,
  open,
  width = DEFAULT_SLIDING_PANEL_WIDTH,
}: SlidingPanelProps) => {
  const prevBodyOverflowStyle = useRef<string | null>(null)

  const transitions = useTransition(open, {
    from: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.05)',
      marginRight: `-${width}`,
    },
    enter: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      boxShadow: '0px 0px 30px 0px rgba(0,0,0,0.20)',
      marginRight: replaceWidthValueAndKeepUnit({ originalWidth: width, newWidthValue: 0 }),
    },
    leave: {
      backgroundColor: 'rgba(0,0,0,0)',
      boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.05)',
      marginRight: `-${width}`,
    },
    onDestroyed: (result: boolean) => {
      if (result) {
        onAfterClose?.()
      }
    },
  })

  const stopEventPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
  }, [])

  useEffect(() => {
    if (open) {
      prevBodyOverflowStyle.current = document.body.style.overflow
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = prevBodyOverflowStyle.current || ''
    }
  }, [open])

  return (
    <>
      {ReactDOM.createPortal(
        transitions(
          ({ backgroundColor, boxShadow, marginRight }, item) =>
            item && (
              <AnimatedPanelBackground
                data-e2e={`${dataE2e}--overlay`}
                onClick={onClose}
                style={{ backgroundColor }}
              >
                <AnimatedPanel
                  className={className}
                  data-e2e={dataE2e}
                  onClick={stopEventPropagation}
                  style={{ boxShadow, marginRight, width }}
                >
                  {!!header && (
                    <SlidingPanelHeader data-e2e={dataE2e} onClose={onClose}>
                      {header}
                    </SlidingPanelHeader>
                  )}
                  <StyledPanelContent inset="medium">{children}</StyledPanelContent>
                  {!!footer && <SlidingPanelFooter data-e2e={dataE2e}>{footer}</SlidingPanelFooter>}
                </AnimatedPanel>
              </AnimatedPanelBackground>
            )
        ),
        document.body
      )}
    </>
  )
}

export default SlidingPanel
