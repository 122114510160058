import { mergeParams } from '@procsea/common/utils'

import {
  GenerateQueriesArgs,
  UseSingleEntityQueryArgs,
  generateQueries,
} from 'src/queries/queryGenerator'
import { BuyerCompanyAsBuyer } from 'src/types'

export const BUYER_COMPANIES_ENDPOINT = 'buyer/buyer_companies'
export const BUYER_COMPANIES_QUERY_KEY = 'buyerCompanies'

export interface GetBuyerCompaniesRequest {
  buyerCompanyId?: Id
}

const generateQueriesConfig: GenerateQueriesArgs<BuyerCompanyAsBuyer, GetBuyerCompaniesRequest> = {
  endpoint: BUYER_COMPANIES_ENDPOINT,
  queryKey: BUYER_COMPANIES_QUERY_KEY,
}

const { useAllEntitiesQuery, useSingleEntityQuery } = generateQueries(generateQueriesConfig)

export const useBuyerCompaniesQuery = useAllEntitiesQuery

type UseBuyerCompanyQueryArgs = UseSingleEntityQueryArgs<
  BuyerCompanyAsBuyer,
  GetBuyerCompaniesRequest
>

export const useBuyerCompanyQuery = (args: UseBuyerCompanyQueryArgs) => {
  const defaultsParams: UseBuyerCompanyQueryArgs = {
    queryOptions: { enabled: !!args.id },
  }

  return mergeParams(useSingleEntityQuery)(defaultsParams)(args)
}
